import { HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { HomePage } from '../models/homePage';
import { PaisModel } from '../models/pais';

const PAGES = {
  usuarios: '/usuarios',
  sesion: '/sesion',
  login: '/login',
  tipoPersona: '/tiposPersonas',
  tipoRol: '/tiposRol',
  tipoCondominio: '/tipo-condominio',
  tipoSeccion: '/tiposSecciones',
  tipoUnidad: '/tipo-unidad',
  tipoEdificio: '/tipo-edificio',
  tipoEmpresa: '/tipoEmpresa',
  empresa: '/empresa',
  vehiculoEmpresa: '/vehiculoEmpresa',
  tipoParentesco: '/tiposParentesco',
  tipoVehiculo: '/tiposVehiculos',
  tipoNotificacion: '/tiposNotificaciones',
  tipoAmenidad: '/tipo-amenidad',
  tiposTelefonos: '/tiposTelefonos',
  tipoMascota: '/tipoMascotas',
  telefonos: '/telefonos',
  uploadimages: '/gestorImagenes',
  empresas: '/empresas',
  personas: '/personas',
  notificacion: '/notificaciones',
  condominios: '/condominios',
  create: '/create',
  findByAllFields: '/findByAllFields',
  findList: '/findList',
  delete: '/delete',
  update: '/update',
  validate: '/validate',
  refreshTkn: '/refresh-token',
  guardarImagen: '/guardarImagen',
  obtenerImagen: '/obtenerImagen',
  eliminarImagen: '/eliminarImagen'
};

const ROUTES = {
  assets: 'assets',
  images: '/images'
};

const URL_BASE= environment.url;

console.log("URL Base");
console.log(URL_BASE);

const IMAGES = `${ROUTES.assets}${ROUTES.images}`;

export const lifeSession = {
  count: 60
};

export const notificationMessage = {
  CONECTION_FAILED: 'Error inesperado, intenta mas tarde.',
  SESSION_INVALID: 'Sesión no valida.',
  SAVE: 'Guardado.',
  CREATE: 'creado exitosamente.',
  UPDATE: 'modificado exitosamente.',
  DELETE: 'borrado exitosamente.'
};

export const endpoints   = {
  //Login: `${URL_BASE}${PAGES.usuarios}${PAGES.sesion}${PAGES.login}`,
  Login: `https://aguitech.com/yoin_test/login.php`,
  //Login: `${URL_BASE}${PAGES.usuarios}${PAGES.sesion}${PAGES.login}`,
  //ValidateLogin: `${URL_BASE}${PAGES.usuarios}${PAGES.sesion}${PAGES.validate}`,
  ValidateLogin: `https://aguitech.com/yoin_test/sesion_validate.php`,
  //RefreshToken: `${URL_BASE}${PAGES.usuarios}${PAGES.sesion}${PAGES.refreshTkn}`,
  RefreshToken: `https://aguitech.com/yoin_test/refresh_token.php`,
  TiposTelefonosCreate: `${URL_BASE}${PAGES.telefonos}${PAGES.tiposTelefonos}${PAGES.create}`,
  TiposTelefonosFindByAllFields: `${URL_BASE}${PAGES.telefonos}${PAGES.tiposTelefonos}${PAGES.findByAllFields}`,
  TiposTelefonosDelete: `${URL_BASE}${PAGES.telefonos}${PAGES.tiposTelefonos}${PAGES.delete}`,
  TiposTelefonosUpdate: `${URL_BASE}${PAGES.telefonos}${PAGES.tiposTelefonos}${PAGES.update}`,
  TipoPersonaCreate: `${URL_BASE}${PAGES.personas}${PAGES.tipoPersona}${PAGES.create}`,
  TipoPersonaFindByAllFields: `${URL_BASE}${PAGES.personas}${PAGES.tipoPersona}${PAGES.findByAllFields}`,
  TipoPersonaDelete: `${URL_BASE}${PAGES.personas}${PAGES.tipoPersona}${PAGES.delete}`,
  TipoPersonaUpdate: `${URL_BASE}${PAGES.personas}${PAGES.tipoPersona}${PAGES.update}`,
  TiposVehiculosCreate: `${URL_BASE}${PAGES.personas}${PAGES.tipoVehiculo}${PAGES.create}`,
  TiposVehiculoFindByAllFields: `${URL_BASE}${PAGES.personas}${PAGES.tipoVehiculo}${PAGES.findByAllFields}`,
  TiposVehiculoDelete: `${URL_BASE}${PAGES.personas}${PAGES.tipoVehiculo}${PAGES.delete}`,
  TiposVehiculoUpdate: `${URL_BASE}${PAGES.personas}${PAGES.tipoVehiculo}${PAGES.update}`,
  TipoMascotaCreate: `${URL_BASE}${PAGES.personas}${PAGES.tipoMascota}${PAGES.create}`,
  TipoMascotaFindByAllFields: `${URL_BASE}${PAGES.personas}${PAGES.tipoMascota}${PAGES.findByAllFields}`,
  TipoMascotaDelete: `${URL_BASE}${PAGES.personas}${PAGES.tipoMascota}${PAGES.delete}`,
  TipoMascotaUpdate: `${URL_BASE}${PAGES.personas}${PAGES.tipoMascota}${PAGES.update}`,
  TipoNotificacionCreate: `${URL_BASE}${PAGES.notificacion}${PAGES.tipoNotificacion}${PAGES.create}`,
  TipoNotificacionFindByAllFields: `${URL_BASE}${PAGES.notificacion}${PAGES.tipoNotificacion}${PAGES.findByAllFields}`,
  TipoNotificacionDelete: `${URL_BASE}${PAGES.notificacion}${PAGES.tipoNotificacion}${PAGES.delete}`,
  TipoNotificacionUpdate: `${URL_BASE}${PAGES.notificacion}${PAGES.tipoNotificacion}${PAGES.update}`,
  TipoSeccionCreate: `${URL_BASE}${PAGES.condominios}${PAGES.tipoSeccion}${PAGES.create}`,
  TipoSeccionFindByAllFields: `${URL_BASE}${PAGES.condominios}${PAGES.tipoSeccion}${PAGES.findByAllFields}`,
  TipoSeccionDelete: `${URL_BASE}${PAGES.condominios}${PAGES.tipoSeccion}${PAGES.delete}`,
  TipoSeccionUpdate: `${URL_BASE}${PAGES.condominios}${PAGES.tipoSeccion}${PAGES.update}`,
  TipoRolCreate: `${URL_BASE}${PAGES.usuarios}${PAGES.tipoRol}${PAGES.create}`,
  TipoRolFindByAllFields: `${URL_BASE}${PAGES.usuarios}${PAGES.tipoRol}${PAGES.findByAllFields}`,
  TipoRolDelete: `${URL_BASE}${PAGES.usuarios}${PAGES.tipoRol}${PAGES.delete}`,
  TipoRolUpdate: `${URL_BASE}${PAGES.usuarios}${PAGES.tipoRol}${PAGES.update}`,
  TiposEmpresaCreate: `${URL_BASE}${PAGES.empresas}${PAGES.tipoEmpresa}${PAGES.create}`,
  TiposEmpresaFindList: `${URL_BASE}${PAGES.empresas}${PAGES.tipoEmpresa}${PAGES.findList}`,
  TiposEmpresaDelete: `${URL_BASE}${PAGES.empresas}${PAGES.tipoEmpresa}${PAGES.delete}`,
  TiposEmpresaUpdate: `${URL_BASE}${PAGES.empresas}${PAGES.tipoEmpresa}${PAGES.update}`,
  TipoParentescoCreate: `${URL_BASE}${PAGES.personas}${PAGES.tipoParentesco}${PAGES.create}`,
  TipoParentescoFindByAllFields: `${URL_BASE}${PAGES.personas}${PAGES.tipoParentesco}${PAGES.findByAllFields}`,
  TipoParentescoDelete: `${URL_BASE}${PAGES.personas}${PAGES.tipoParentesco}${PAGES.delete}`,
  TipoParentescoUpdate: `${URL_BASE}${PAGES.personas}${PAGES.tipoParentesco}${PAGES.update}`,
  //UploadImagesCreate: `${URL_BASE}${PAGES.uploadimages}${PAGES.guardarImagen}`,
  //UploadImagesCreate: `http://localhost:8081${PAGES.uploadimages}${PAGES.guardarImagen}`,
  UploadImagesCreate: `https://aguitech.com/yoin_test/file.php`,
  UploadImagesGet: `${URL_BASE}${PAGES.uploadimages}${PAGES.obtenerImagen}`,
  UploadImagesDelete: `${URL_BASE}${PAGES.uploadimages}${PAGES.eliminarImagen}`,
  EmpresaFindList: `${URL_BASE}${PAGES.empresas}${PAGES.empresa}${PAGES.findList}`,
  EmpresaCreate: `${URL_BASE}${PAGES.empresas}${PAGES.empresa}${PAGES.create}`,
  EmpresaUpdate: `${URL_BASE}${PAGES.empresas}${PAGES.empresa}${PAGES.update}`,
  VehiculoFindList: `${URL_BASE}${PAGES.empresas}${PAGES.vehiculoEmpresa}${PAGES.findList}`,
  VehiculoEmpresaCreate: `${URL_BASE}${PAGES.empresas}${PAGES.vehiculoEmpresa}${PAGES.create}`,
  VehiculoEmpresaUpdate: `${URL_BASE}${PAGES.empresas}${PAGES.vehiculoEmpresa}${PAGES.update}`,
  
};

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

//paginator variables
export const PAGINATOR = {
  pageSize: 10,
  pageSizeOptions: [5, 10, 25, 100]
}

export const REGEX = {
  letrasNumeros: /^[A-Za-z0-9-_\sáéíóúÁÉÍÓÚñÑüÜ]+$/,
  emailPattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
  phone: /^\d+$/,
  ladaPhone: /^[+]?\d+$/,
};

// Paises configurados para la app
export const PAISES : PaisModel[] = [
  {
    idioma : 'español',
    pais : 'Mexico',
    idiomaShort : 'es',
    paisShort: 'Mex',
    lada: '+52',
    formatPhone: 'ej. 044 222 123 4567'
  },
  {
    idioma : 'ingles',
    pais : 'United States',
    idiomaShort : 'en',
    paisShort: 'USA',
    lada: '+1',
    formatPhone: 'e.g. (201) 555-0123'
  },
];

export const HomePages: HomePage[] = [
  {
    icon: 'dashboard',
    title: 'anuncios',
    route: '/home-page/anuncios'
  },
  {
    icon: 'date_range',
    title: 'amenidades',
    route: '/home-page/amenidades'
  },
  {
    icon: 'business',
    title: 'empresa',
    route: '/home-page/empresa'
  },
  {
    icon: 'face',
    title: 'rol-usuario-empleado',
    route: '/home-page/rol-usuario-empleado'
  },
  {
    icon: 'dashboard',
    title: 'back-office',
    route: '/home-page/back-office'
  },
  {
    icon: 'home',
    title: 'condominio',
    route: '/home-page/condominios'
  },
  {
    icon: 'home',
    title: 'Admin condominio',
    route: '/home-page/admin-estructura-condominios'
  },
  {
    icon: 'public',
    title: 'condominio-detalle',
    route: '/home-page/condominios/detalle'
  },
  {
    icon: 'dashboard',
    title: 'secciones',
    route: '/home-page/secciones'
  },
  {
    icon: 'business',
    title: 'edificios',
    route: '/home-page/edificios'
  },
  {
    icon: 'business',
    title: 'edificio detalle',
    route: '/home-page/edificios/detalle'
  },
  {
    icon: 'dashboard',
    title: 'encuestas',
    route: '/home-page/encuestas'
  },
  {
    icon: 'public',
    title: 'banner',
    route: '/home-page/banner'
  },
  {
    icon: 'contact_support',
    title: 'support-ticket',
    route: '/home-page/support-ticket-list'
  },
  {
    icon: 'public',
    title: 'support-ticket-detalle',
    route: '/home-page/support-ticket-list/detalle'
  },
  {
    icon: 'public',
    title: 'General Encuestas',
    route: '/home-page/general-encuestas'
  },
  {
    icon: 'public',
    title: 'Detalle Encuestas',
    route: '/home-page/general-encuestas/detalle'
  },
  {
    icon: 'monetization_on',
    title: 'contabilidad',
    route: '/home-page/contabilidad'
  },
  {
    icon: 'chat',
    title: 'chat',
    route: '/home-page/chatRoom'
  },
  {
    icon: 'public',
    title: 'General amenidades',
    route: '/home-page/amenidades/general'
  },
  {
    icon: 'compare_arrows',
    title: 'Puntos Accesos',
    route: '/home-page/punto-acceso'
  },
  {
    icon: 'compare_arrows',
    title: 'Movimientos registrados',
    route: '/home-page/punto-acceso/movimientosRegistrados'
  },
  {
    icon: 'compare_arrows',
    title: 'Checkin',
    route: '/home-page/punto-acceso/checkin'
  },
  {
    icon: 'compare_arrows',
    title: 'Registro Acceso Manual',
    route: '/home-page/punto-acceso/registro-acceso-manual'
  }
  
]