import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { DataService } from './services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { LifeTimeSesionService } from './services/life-time-session.service';
import { ModalData } from './common/vo/modalData';
import { SessionDialogComponent } from './common/components/session-dialog/session-dialog.component';
import { SesionService } from './services/sesion.service';

declare const $: any;

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  private _router: Subscription;
  isLogged: boolean = true;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private _dataService: DataService,
    private _sesion: SesionService,
    private _lifeTimeSesion: LifeTimeSesionService,
    public translate: TranslateService,
    public dialog: MatDialog
    ) {
      //Traducción de la página
    this.translate.addLangs(['es', 'en']);
    let language = this._sesion.getNavigatorLanguage().split('_')[0];
    this.translate.setDefaultLang('es');

    this.setNotificationMessage();
    this.showPageUser();
    this.getDialog();
    // observable para visualizar pop up de inactividad
    this._lifeTimeSesion.getInactivity().subscribe(logOut=> {
      if(logOut){
        let lifeDialog = new ModalData();
        lifeDialog.component = SessionDialogComponent;
        lifeDialog.instance = [];
        this._dataService.setModalData(lifeDialog);
      }
    });
    // Subscripcion al servicio para activar/desactivar la barra de loading.
    this._dataService.getIsLoadingEvent().subscribe( status => {
      this.isLoading = status;
    });
  }

  ngOnInit() {
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      const body = document.getElementsByTagName('body')[0];
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        modalBackdrop.remove();
      }

    });
  }

  /**
   * Activa el modal para construirse con el componente obtenido del servicio.
   * @param getModalData
   *
   */
  getDialog(): void {
    this._dataService.getModalData().subscribe(data => {
      this.dialog.open(data.component, {
        data: data.instance,
        disableClose: (data.disableClose != null || data.disableClose != undefined) ? data.disableClose : true,
        panelClass: 'custom'
      });
    });
  }

  /**
   *  muestra las paginas que solo puede ver el usuario según sus roles
   */
  private showPageUser() {
    console.log("paginas");
    
  }


  /**
   * setea un mensaje en pantalla
   */
  private setNotificationMessage(){
    this._dataService
    .getNotificationMessage()
    .subscribe(msg => {
            $.notify({
              icon: msg.icon,//notifications , warning
              message: msg.message
            }, {
                type: msg.type,//success , warning
                timer: 6000,
                placement: {
                  from: 'top',
                  align: 'right'
                },
                template: `<div" data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">` +
                  '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
                  '<i class="material-icons" data-notify="icon">error</i>' +
                  '<span data-notify="title">{1}</span> ' +
                  `<span id="${msg.code} data-notify="message">{2}</span>` +
                  '<div class="progress" data-notify="progressbar">' +
                    '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                  '</div>' +
                  '<a href="{3}" target="{4}" data-notify="url"></a>' +
                '</div>'
              });
        
        
      });
    
  }
}
