import { Component } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'condominio-list',
    templateUrl: 'condominio-list.component.html',
    styleUrls: ['./condominio-list.component.css']
})

export class CondominioList {
    test: Date = new Date();
    nameCondominio: string;
    nombresCondominios: Array<any>;

    constructor(private _data: DataService) {
        this.nameCondominio = this._data.retriveStorage("nombreCondominioSeleccionado");
        this.nombresCondominios = this._data.retriveStorage("nombresCondominios");
    }

    selectCondominio(id, nombre){
        this._data.addStore("nombreCondominioSeleccionado", nombre);
        this._data.addStore("idCondominioSeleccionado", id);
        this.nameCondominio = this._data.retriveStorage("nombreCondominioSeleccionado");
    }

    ngOnInit() {
        
    }
}
