// general imports
import { Injectable } from '@angular/core';
import { endpoints } from './../common/utils/configUrl';
import { Observable} from 'rxjs';

// imports VO
import { ResponseVO } from '../common/vo/responseVO';
import { RequestVO } from '../common/vo/requestVO';
import { UploadImageVO } from '../common/vo/uploadImageVO';

// services
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})

export class UploadImageService {
  private urlCreate: string = endpoints.UploadImagesCreate;
  private urlGet: string = endpoints.UploadImagesGet;
  private urlDelete: string = endpoints.UploadImagesDelete;
  
  //private _service:GlobalService;
  constructor(private _service:GlobalService) { }
  /**
   * Creación de un tipo telefono
   * @param form : contenido para el campo de parameters
   */
  //uploadImagesCreate(form: RequestVO<UploadImageVO>, files: FileList): Observable<ResponseVO<UploadImageVO>> {
  uploadImagesCreate(form: RequestVO<UploadImageVO>): Observable<ResponseVO<UploadImageVO>> {
    

    console.log("Form");
    console.log(form);
    console.log("--------");
    
    
    //console.log(urlCreate);



    return this._service.postFilles(form, this.urlCreate);
  }

  /**
   * Eliminacion de un tipo telefono
   * @param id : id de un dato de tipo telefono
   */
  uploadImagesDelete(parameters: RequestVO<UploadImageVO>): Observable<ResponseVO<UploadImageVO>> {
    return this._service.post(parameters, this.urlDelete);
  }

  /**
   * Busqueda por criterios de un tipo telefono
   * @param form : contenido para el campo de parameters
   */
  uploadImagesGet(parameters: RequestVO<UploadImageVO>): Observable<ResponseVO<UploadImageVO[]>> {
    
    return this._service.post(parameters, this.urlGet);
  }
}
