import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleCardComponent } from '../common/components/title-card/title-card.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatFormFieldModule,
  MatDatepickerModule
} from '@angular/material';
import { ButtonCreateComponent } from '../common/components/button-create/button-create.component';
import { CalendarComponent } from '../common/components/calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from '../shared/footer/footer.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from '../shared/navbar/navbar.component';
import { DetalleTelefonosComponent } from '../pages/catalogo/telefonos/detail/detalle-telefonos.component';
import { DetalleEmpresasComponent } from '../pages/catalogo/empresas/detail/detalle-empresas.component';
import { DetalleTipoPersonaComponent } from '../pages/catalogo/tipo-personas/detail/detalle-tipo-personas.component';
import { DetalleTipoVehiculoComponent } from '../pages/catalogo/tipo-vehiculo/detail/detalle-tipo-vehiculo.component';
import { DetalleTipoSeccionComponent } from '../pages/catalogo/tipo-seccion/detail/detalle-tipo-seccion.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { SessionDialogComponent } from '../common/components/session-dialog/session-dialog.component';
import { DetalleTipoMascotaComponent } from '../pages/catalogo/tipo-mascota/detail/detalle-tipo-mascota.component';
import { DetalleNotificacionesComponent } from '../pages/catalogo/notificaciones/detail/detalle-notificaciones.component';
import { DetalleTipoRolComponent } from '../pages/catalogo/tipo-rol/detail/detalle-tipo-rol.component';
import { BasicSearchComponent } from '../common/components/basic-search/basic-search.component';
import { ButtonEditComponent } from '../common/components/button-edit/button-edit.component';
import { ButtonDeleteComponent } from '../common/components/button-delete/button-delete.component';
import { PhoneCountryDirective } from '../common/directives/phone-country.directive';
import { ButtonPositiveComponent } from '../common/components/button-positive/button-positive.component';
import { ButtonNegativeComponent } from '../common/components/button-negative/button-negative.component';
import { ChatFormComponent } from '../common/components/chat-form/chat-form.component';
import { ButtonSearchComponent } from '../common/components/button-search/button-search.component';
import { CondominioList } from '../shared/condominio-list/condominio-list.component';

export const MATERIAL_COMPONENTS = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatDatepickerModule,
  HttpClientModule
];

export const TRANSLATE = [TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
})]

@NgModule({
  declarations: [
    TitleCardComponent,
    CalendarComponent,
    ButtonCreateComponent,
    FooterComponent,
    SidebarComponent,
    NavbarComponent,
    ButtonSearchComponent,
    DetalleTelefonosComponent,
    DetalleEmpresasComponent,
    DetalleTipoPersonaComponent,
    DetalleTipoVehiculoComponent,
    DetalleTipoSeccionComponent,
    ConfirmationDialogComponent,
    SessionDialogComponent,
    DetalleTipoMascotaComponent,
    DetalleNotificacionesComponent,
    DetalleTipoRolComponent,
    BasicSearchComponent,
    ButtonEditComponent,
    ButtonDeleteComponent,
    PhoneCountryDirective,
    ButtonPositiveComponent,
    ButtonNegativeComponent,
    ChatFormComponent,
    CondominioList
  ],
  imports: [
    CommonModule,
    RouterModule,
    FullCalendarModule, // import the FullCalendar module! will make the FullCalendar component available
    MATERIAL_COMPONENTS,
    TRANSLATE,
    FormsModule,
    ReactiveFormsModule,

  ],
  exports: [
    TitleCardComponent,
    ButtonSearchComponent,
    FooterComponent,
    ButtonCreateComponent,
    CalendarComponent,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    MATERIAL_COMPONENTS,
    SidebarComponent,
    NavbarComponent,
    BasicSearchComponent,
    ButtonEditComponent,
    ButtonDeleteComponent,
    ButtonPositiveComponent,
    ButtonNegativeComponent,
    ChatFormComponent,
    DetalleTelefonosComponent,
    DetalleEmpresasComponent,
    DetalleTipoPersonaComponent,
    DetalleTipoVehiculoComponent,
    DetalleTipoSeccionComponent,
    ConfirmationDialogComponent,
    SessionDialogComponent,
    DetalleTipoMascotaComponent,
    DetalleNotificacionesComponent,
    DetalleTipoRolComponent,
    PhoneCountryDirective,
    CondominioList
  ],

  entryComponents: [
    DetalleTelefonosComponent,
    DetalleEmpresasComponent,
    DetalleTipoPersonaComponent,
    DetalleTipoVehiculoComponent,
    DetalleTipoSeccionComponent,
    ConfirmationDialogComponent,
    SessionDialogComponent,
    DetalleTipoMascotaComponent,
    DetalleNotificacionesComponent,
    DetalleTipoRolComponent
  ]
})
export class GlobalModule { }
