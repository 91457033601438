import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ListaMenusVO, Menus } from '../vo/listaMenusVO';
import { SesionService } from '../../services/sesion.service';
import { ErrorVO } from '../vo/errorVO';
import { LifeTimeSesionService } from '../../services/life-time-session.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  private href : string[];
  constructor(private _data: DataService, public router: Router, private _session: SesionService, private _life: LifeTimeSesionService ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // se ejecuta el contenido del if solamente cuando se a refrescado la pagina
    if(!this._data.getRefreshPage()){
      
      // obtener token del local storage
      let token = localStorage.getItem('token');
      if (token){
        // obtener pagina a ingresar
        this.href = new Array<string>();
        this.href = state.url.split('/');
        let page = this.href.pop().toLowerCase();
        let validateSession = this.validateSession(token, page);
        return validateSession;
      }
  
      this.router.navigateByUrl('');
    }
    return true;
  }

  /**
   * Valida si el token aun tiene sesión valida
   * @param token : token guardado del localStorage
   * @param page : pagina a ingresar
   */
  private validateSession(token: string, page: string):Promise<boolean>{
    return this._session.validateLogin(token).toPromise().then(data => {
      
      if(data.success){
        let listaMenus = data.data.listaMenus;
        this._data.setInfoLogin(listaMenus);

          // avisa que la pagina fue cargada por primera vez
          this._data.setRefreshPage(true);
          //TODO: invalidar token viejo
          //Resfrescar el token
          this._session.refreshToken(token).subscribe( data =>{
            this._data.setToken(data.data.token);
            this._life.start(this._data.decodeToken());
          }, 
          // error presentado al no refrescar el token, cerrar sesion.
          (err:ErrorVO) =>{
            this._life.logOut();
            this._data.addError(err.message);
          });
          this._data.setToken(data.data.token);
          //inicializa la vida del token
          return true;
      }else{
        console.log(data);
        this._data.addErrors(data.errors);
        this._life.logOut();
      }
    }).catch( (err) => {
      let error = new ErrorVO();
      error = err[0];
      this._data.addError(error.message);
      this.router.navigateByUrl('login');
      return false;
    });
    }


    /**
     * metodo que valida si la pagina a ingresar es valido para el usuario
     * @param page pagina a ingresar
     * @param listaMenus lista de menus del sidebar
     */
  private validatePage(page:string, listaMenus: ListaMenusVO):boolean{
     //valida si la pagina a entrar es el dashboard
    if(page === 'dashboard'){
      return true;
    }

    // obtener la ruta padre
    let pageMaster = new Array<Menus>();
    let mp = this.href.pop().toLocaleUpperCase();
    pageMaster = listaMenus[mp].listaSubMenu;
    
    // valida si la pagina a entrar es permitida por el usuario
    let validateHref = pageMaster.find(f => {
      return f.path.toLocaleLowerCase().indexOf(page)!= -1
    });
    if(validateHref){
      return true;
    }
    this.router.navigate(['dashboard']);
  }
}
