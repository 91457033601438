import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { emailMatcher, passwordMatcher, phoneMatcher } from 'src/app/common/utils/mustMatch';
import { REGEX, PAISES } from 'src/app/common/utils/configUrl';
import { ReCaptcha2Component } from 'ngx-captcha';
import { EncryptService } from '../../common/services/encrypt/encrypt.service';
import { MatStepper } from '@angular/material';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { PaisModel } from 'src/app/common/models/pais';
import { SesionService } from 'src/app/services/sesion.service';
import { ButtonPositiveComponent } from 'src/app/common/components/button-positive/button-positive.component';

@Component({
    selector: 'app-register-cmp',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  paises : PaisModel[];
  paisSelected: PaisModel
  labelPosition = 'bottom';
  test: Date = new Date();
  isLinear = false;
  formGroupPhone: FormGroup;
  formGroupEmail: FormGroup;
  fgActivatingAccount : FormGroup;
  formGroupPolicy : FormGroup;
  formGroupInfo: FormGroup;
  formGroupPass: FormGroup;

  // Configuracion del captcha
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
  @ViewChild('codigo1') code1: ElementRef;
  @ViewChild('codigo2') code2: ElementRef;
  @ViewChild('codigo3') code3: ElementRef;
  @ViewChild('codigo4') code4: ElementRef;
  @ViewChild('codigo5') code5: ElementRef;
  @ViewChild('codigo6') code6: ElementRef;
  @ViewChild('userIdEmail') userIdEmail: ElementRef;
  siteKey = '6LcvoUgUAAAAAJJbhcXvLn3KgG-pyULLusaU4mL1';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  checkCreateAccount: boolean = true;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'es';
  public type: 'image' | 'audio';
  public useGlobalDomain = false;

  constructor(private _fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private _sesion: SesionService,
              private router: Router,
              private _encryptService: EncryptService) {}

  ngOnInit(): void {
    this.getPaises();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('register-page');
    body.classList.add('off-canvas-sidebar');
    this.buildForm();
  }

  changeFocus(number, event){
    if(event.key != "Backspace"){
      if(number == 1 && this.code1.nativeElement.value !=""){
        this.code2.nativeElement.focus();
      }else if(number == 2 && this.code2.nativeElement.value !=""){
        this.code3.nativeElement.focus();
      }else if(number == 3 && this.code3.nativeElement.value !=""){
        this.code4.nativeElement.focus();
      }else if(number == 4 && this.code4.nativeElement.value !=""){
        this.code5.nativeElement.focus();
      }else if(number == 5 && this.code5.nativeElement.value !=""){
        this.code6.nativeElement.focus();
      }
    }else{
      if(number == 2){
        this.code1.nativeElement.focus();
      }else if(number == 3){
        this.code2.nativeElement.focus();
      }else if(number == 4){
        this.code3.nativeElement.focus();
      }else if(number == 5){
        this.code4.nativeElement.focus();
      }else if(number == 6){
        this.code5.nativeElement.focus();
      }
    }
    
  }

  // se obtienen los idiomas predefinidos
  getPaises(){
    this.paises = PAISES;
  }

  // se obtiene el pais seleccionado por el usuario
  getPais(pais: PaisModel){
    this.paisSelected = pais;
    this.formGroupPhone.controls.userIdPhoneLada.setValue(pais.lada);
  }

  // Construcción de los los objetos para los formularios reactivos.
  private buildForm() {
    this.paisSelected = this._sesion.getNavigatorLanguage() === 'es' ? this.paises[0] : this.paises[1];
      this.formGroupEmail = this._fb.group({
        userIdEmail: ['', [Validators.required, Validators.pattern(REGEX.emailPattern)]],
        confirmEmail: ['', [Validators.required]],
        phoneValid: [false, [Validators.required]],
      }, { validator: emailMatcher });


      this.formGroupPolicy = this._fb.group({
        termsEnabled: ['', Validators.requiredTrue ],
        recaptcha: ['', Validators.required]
      });
      
      this.formGroupPhone = this._fb.group({
        pais: [this.paisSelected, [Validators.required]],
        userIdPhoneLada: ['', [Validators.required, Validators.pattern(REGEX.ladaPhone)]],
        userIdPhone: ['', [Validators.required, Validators.pattern(REGEX.phone)]],
        confirmPhone: ['', [ Validators.required]]
      }, { validator: phoneMatcher });

      let lada = this.formGroupPhone.get('userIdPhoneLada')
      lada.disable();

      this.fgActivatingAccount = this._fb.group({
        code1: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("[0-9]+$")]],
        code2: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("[0-9]+$")]],
        code3: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("[0-9]+$")]],
        code4: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("[0-9]+$")]],
        code5: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("[0-9]+$")]],
        code6: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("[0-9]+$")]]
      });

      this.formGroupInfo = this._fb.group({
        nombre: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
        apellidoPat: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
        apellidoMat: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
        typecompany: ['', [Validators.required]],
        company: ['', [Validators.required, Validators.minLength(4),Validators.maxLength(50)]],
        notificationsEnabled: [false],
      });

      this.formGroupPass = this._fb.group({
        password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
        confirmPassword: ['', Validators.required]
      }, { validator: passwordMatcher });

    this.getPais(this.paisSelected);

  }

  // Getters para tener acceso a los controles / propiedades.
  get fEmail() { return this.formGroupEmail.controls; }
  get fPhone() { return this.formGroupPhone.controls; }
  get fActivAccount() { return this.fgActivatingAccount.controls; }
  get fPass() { return this.formGroupPass.controls; }
  get info() { return this.formGroupInfo.controls; }
  get confirm() { return this.formGroupPolicy.controls; }

  validatePhone(stepper: MatStepper){
    if(this.formGroupPhone.valid){
      this.formGroupEmail.controls.confirmEmail.setValidators([]);
      this.formGroupEmail.controls.confirmEmail.updateValueAndValidity();
      this.formGroupEmail.controls.userIdEmail.setValidators([]);
      this.formGroupEmail.controls.userIdEmail.updateValueAndValidity();
      this.formGroupEmail.value.phoneValid = this.formGroupPhone.valid;
      this.formGroupEmail.controls.confirmEmail.setValidators([Validators.required]);
      this.formGroupEmail.controls.confirmEmail.updateValueAndValidity();
      this.formGroupEmail.controls.userIdEmail.setValidators([Validators.required, Validators.pattern(REGEX.emailPattern)]);
      this.formGroupEmail.controls.userIdEmail.updateValueAndValidity();
    }
    stepper.next();
  }

  registrar() {
    let userIdEmail = this.formGroupInfo.value;
    let password = this._encryptService.encryptDataToBack(this.formGroupPass.get('password').value);
    console.log(userIdEmail);
    console.log(password);

    swal({
      title: "Registro Completado!",
      text: "Ya puedes iniciar sesión",
      buttonsStyling: false,
      confirmButtonClass: "btn btn-round registerNext",
      confirmButtonText: 'Cerrar',
      type: "success"
  }).catch(swal.noop)


    this.router.navigate(['login']);
    // this._usuarioService.register(registro).subscribe( response => {
    // });
  }

  changeBorder(color: string){
    let img = document.getElementById('wizardPicturePreview') as HTMLImageElement;
    img.style.borderColor = color;
  }

  // Metodos para la funcionalidad del captcha
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
  // Terminan metodos para la funcionalidad del captcha

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('register-page');
    body.classList.remove('off-canvas-sidebar');
  }

}
