import { Component, OnInit } from '@angular/core';
import { UploadImageService } from 'src/app/services/upload-image.service';
import { RequestVO } from 'src/app/common/vo/requestVO';
import { UploadImageVO } from 'src/app/common/vo/uploadImageVO';
import { Subscription } from 'rxjs';
import { ErrorVO } from 'src/app/common/vo/errorVO';
import { FormGroup, FormBuilder } from '@angular/forms';

declare const $: any;
@Component({
    selector: 'app-uploadimages-cmp',
    templateUrl: 'upload-images.component.html',
})

export class UploadImgs{

    /**
    constructor(private _uploadImageService: UploadImageService,
        private _dataService: DataService,
        private _life: LifeTimeSesionService) { 
    }
    */
    
    //files: any = [];
    files: Array<File> = [];
    //filesSend: Array<File> = [];
    filesSend: Array<File> = [];
    filesAttach: Array<File> = [];

    observableSend: Subscription;

    public formData: FormGroup;
    
    constructor(private _upload:UploadImageService,
      private _formBuilder:FormBuilder){
        
        this.FormDataBuilder();
    }
    

    FormDataBuilder(){
      this.formData = this._formBuilder.group({
        imagenes : [''],
        nombreTipoComponente : [''],
        nombreTipoImagen : [''],
        idComponente: ['']
      });
    }

    uploadFile(event) {

        let formDataX = new FormData();
      

        console.log("Event");
        console.log(event);

        console.log("FILES");
        console.log(this.filesAttach);

        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            this.files.push(element.name);
            this.filesAttach.push(<File>element);

            console.log("Element Files");
            console.log(element);
            console.log(element.rawFile);


            console.log("Element");
            console.log(element);


            console.log("this.file");
            console.log(this.filesAttach);

            /*
            console.log("Probando");
            console.log(element);
            console.log("Probando2");
            console.log(element.files);
            console.log(element.File);

            console.log("checa elmer");
            formDataX.append('usasdfghername', 'Chris');
            */

            /**
            formDataX.set('username', 'Chris');

            formDataX.append('userpic', element, 'chris.jpg');
            //formDataX.append('usernamearchivo', element);
            //formData.append('userpic', myFileInput.files[0], 'chris.jpg');

            console.log("Aqui form data");

            */

            console.log("Haciendo append");
           formDataX.append("imagenes[0]", element);

            formDataX.forEach((value, key) => {
              console.log("into form");
              console.log(key + " " + value)
            });


            /*
            console.log("form heyX");
            console.log(formDataX);
            */
        }  
    }
    deleteAttachment(index) {
        this.files.splice(index, 1)
    }
    guardar_imagenes(){
        //let images = new RequestVO<UploadImageVO>();

        //let params = new RequestVO<CatalogoVO>();

        this.filesAttach;

        this.filesAttach.forEach((value, key) => {
          console.log("Files attach");
          console.log(key + " " + value)
        });


        let parameters = new RequestVO<UploadImageVO>();

        parameters.parameters = new UploadImageVO();
        //parameters.imagenes = 
        console.log(this.files);

        console.log("Prueba attach");

        //console.log(this.filesSend);
        console.log(this.filesAttach);


        /*
        parameters.parameters.imagenes = this.filesSend;

        parameters.parameters.nombreTipoComponente = "PERSONA";
        parameters.parameters.nombreTipoImagen = "PERFIL";
        parameters.parameters.idComponente = 1;
        */
        
        this.formData.value.nombreTipoComponente = "PERSONA";
        this.formData.value.nombreTipoImagen = "PERFIL";
        this.formData.value.idComponente = 1;
        //this.formData.value.imagenes = this.filesSend;
        this.formData.value.imagenes = this.filesAttach;

        parameters.parameters = this.formData.value;
        console.log(parameters);



        var formDataX = new FormData();
        formDataX.append('username', 'Christian');
        formDataX.append('files', 'Christian');
        formDataX.forEach((value, key) => {
          console.log(key + " " + value)
        });
        //formData.append('userpic', myFileInput.files[0], 'chris.jpg');

        console.log("form hey");
        console.log(formDataX);
        
        //this.observableSend = this._upload.uploadImagesCreate(parameters).subscribe(
        this.observableSend = this._upload.uploadImagesCreate(parameters).subscribe(
            (response) => {
              
              if (response.success) {
                console.log("Se envio");
                //response.data.forEach((element) => {
                //  this.dataTable.dataRows.push(element);
                //});
                
              } else {
                console.log("No se envio");
                //this._dataService.addErrors(response.errors);
              }
            },
            (error: ErrorVO[]) => {
              //Valida token   -604 = El token no es válido
              console.log("Prueba error");
              console.log(error);
              console.log("Prueba final error");
              //console.log(error[0].code);
              



              if (error[0].code === '-604') {
                // logOut
                //this._life.logOut();
              }
              // Se muestran otros errores, no tratados o relacionados por el backend.
              //this._dataService.addErrors(error);
              //this._dataService.stopLoading();
            });
        }
        
        

        /*
    validar_extension(){
        console.log("Validando");
    }
    */

}

