// general imports
import { Injectable } from '@angular/core';
import { endpoints, notificationMessage } from './../common/utils/configUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';

// imports VO
import { ResponseVO } from '../common/vo/responseVO';
import { RequestVO } from '../common/vo/requestVO';
import { CatalogoVO } from '../common/vo/catalogoVO';
import { ErrorVO } from '../common/vo/errorVO';
import { CatalogoResponseVO } from '../common/vo/catalogoResponseVO';

// services
import { DataService } from './data.service';
import { LifeTimeSesionService } from './life-time-session.service';
import { GlobalService } from './global.service';
import { empresaFindListResponseVO, TipoEmpresaVO, empresaFindListRequestVO, createEmpresaRequestVO, updateEmpresaRequestVO, vehiculoEmpresaFindListRequestVO, vehiculoEmpresaFindListResponseVO, createVehiculoEmpresaRequestVO, updateVehiculoEmpresaRequestVO } from '../common/vo/empresaVO';

/**
 * Service created by: irvin uriel
 */

@Injectable()
export class EmpresasService {
  // url tipo empresa
  private urlCreateTipoEmpresa: string = endpoints.TiposEmpresaCreate;
  private urlTiposEmpresaFindList: string = endpoints.TiposEmpresaFindList;
  private urlDelete: string = endpoints.TiposEmpresaDelete;
  private urlUpdate: string = endpoints.TiposEmpresaUpdate;
  
  // url empresa
  private urlEmpresaFindList: string = endpoints.EmpresaFindList;
  private urlCreateEmpresa: string = endpoints.EmpresaCreate;
  private urlUpdateEmpresa: string = endpoints.EmpresaUpdate;
  
  // url vehiculos
  private urlVehiculoFindList: string = endpoints.VehiculoFindList;
  private urlCreateVehiculoEmpresa: string = endpoints.VehiculoEmpresaCreate;
  private urlUpdateVehiculoEmpresa: string = endpoints.VehiculoEmpresaUpdate;

  private empresa: empresaFindListResponseVO;

  constructor(private _http: HttpClient,
    private _dataService: DataService,
    private _life: LifeTimeSesionService,
    private _global: GlobalService,
    public _dialog: MatDialog) { }

  // ********************* tipo empresa service******************
  /**
   * Creación de un tipo empresa
   * @param form : contenido para el campo de parameters
   */
  tiposEmpresasCreate(form: CatalogoVO): Observable<ResponseVO<CatalogoResponseVO>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    // genera requestVO
    let requestVO = new RequestVO<CatalogoVO>();
    requestVO.parameters = form;
    return this._http
      .post<ResponseVO<CatalogoResponseVO>>(this.urlCreateTipoEmpresa, requestVO, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Creación de un tipo telefono
   * @param form : contenido para el campo de parameters
   */
  tiposEmpresasUpdate(form: CatalogoVO): Observable<ResponseVO<CatalogoResponseVO>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    // genera requestVO
    let requestVO = new RequestVO<CatalogoVO>();
    requestVO.parameters = form;
    return this._http
      .put<ResponseVO<CatalogoResponseVO>>(this.urlUpdate, requestVO, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Eliminacion de un tipo telefono
   * @param id : id de un dato de tipo telefono
   */
  tiposEmpresasDelete(id: number): Observable<ResponseVO<CatalogoVO>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    return this._http
      .delete<ResponseVO<CatalogoVO>>(`${this.urlDelete}/${id}`, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Busqueda por criterios de un tipo Empresa
   * @param form : contenido para el campo de parameters
   */
  tiposEmpresasFindList(parameters: TipoEmpresaVO, page: number, size: number, orderBy: string, orderType: string): Observable<ResponseVO<CatalogoResponseVO[]>> {
    // genera requestVO
    let requestVO = new RequestVO<TipoEmpresaVO>();
    requestVO.parameters = parameters;
    requestVO.page = page;
    requestVO.size = size;
    requestVO.orderBy = orderBy;
    requestVO.orderType = orderType;
    return this._global.post(requestVO, this.urlTiposEmpresaFindList);
  }

  /**
   * Funcion para confirmar la eliminacion del registro.
   * Invoca un Matdialog para realizar la confirmacion de eliminación.
   */
  eliminarTipoEmpresa(infoRow: CatalogoResponseVO): void {

    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: infoRow,
      disableClose: true
    });
    // Espera el cierre de la Modal, lo cual indicará que se aprobo la eliminacion.
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._dataService.startLoading();
        // Se lanza el servicio delete TipoEmpresa
        this.tiposEmpresasDelete(result.id).subscribe(
          (response) => {
            // Se valida que la transaccion se haya realizado con exito y se notifica,
            // de lo contrario se muestran los errores.
            if (response.success) {
              this._dataService.setFetchData(true);
              this._dataService.setSuccessNotificationMessage(`${infoRow.idNombre} ${notificationMessage.DELETE}`);
            } else {
              this._dataService.addErrors(response.errors);
            }
            this._dataService.stopLoading();
          },
          (error: ErrorVO[]) => {
            this._dataService.stopLoading();
            this._dataService.addErrors(error);
          });
      }
    });

  }









  // ********************* Empresa service******************

  /**
 * Busqueda por criterios de una Empresa
 * @param form : contenido para el campo de parameters
 */
  empresasFindList(parameters: empresaFindListRequestVO, page: number, size: number, orderBy: string, orderType: string): Observable<ResponseVO<empresaFindListResponseVO[]>> {
    // genera requestVO
    let requestVO = new RequestVO<empresaFindListRequestVO>();
    requestVO.parameters = parameters;
    requestVO.page = page;
    requestVO.size = size;
    requestVO.orderBy = orderBy;
    requestVO.orderType = orderType;
    return this._global.post(requestVO, this.urlEmpresaFindList);
  }

  /**
   * Creación de una empresa
   * @param form : contenido para el campo de parameters
   */
  createEmpresa(form: createEmpresaRequestVO): Observable<ResponseVO<number>> {
    // genera requestVO
    let requestVO = new RequestVO<createEmpresaRequestVO>();
    requestVO.parameters = form;
    return this._global.post(requestVO, this.urlCreateEmpresa);
  }
  /**
   * Actualización de una empresa
   * @param form : contenido para el campo de parameters
   */
  updateEmpresa(form: updateEmpresaRequestVO): Observable<ResponseVO<number>> {
    return this._global.put(form, this.urlUpdateEmpresa);
  }

  // geters y seters de empresa

  public setEmpresa(empresa: empresaFindListResponseVO): void {
    this.empresa = empresa;
  }

  public getEmpresa(): empresaFindListResponseVO {
    return this.empresa;
  }








  // ********************* vehiculo empresa service******************

  /**
 * Busqueda por criterios de un vehiculo
 * @param form : contenido para el campo de parameters
 */
  vehiculoFindList(parameters: vehiculoEmpresaFindListRequestVO, page: number, size: number, orderBy: string, orderType: string): Observable<ResponseVO<vehiculoEmpresaFindListResponseVO[]>> {
    // genera requestVO
    let requestVO = new RequestVO<vehiculoEmpresaFindListRequestVO>();
    requestVO.parameters = parameters;
    requestVO.page = page;
    requestVO.size = size;
    requestVO.orderBy = orderBy;
    requestVO.orderType = orderType;
    return this._global.post(requestVO, this.urlVehiculoFindList);
  }

  /**
 * Creación de un vehiculo empresa
 * @param form : contenido para el campo de parameters
 */
  createVehiculoEmpresa(form: createVehiculoEmpresaRequestVO): Observable<ResponseVO<number>> {
    // genera requestVO
    let requestVO = new RequestVO<createVehiculoEmpresaRequestVO>();
    requestVO.parameters = form;
    return this._global.post(requestVO, this.urlCreateVehiculoEmpresa);
  }

  /**
 * Actualización de una empresa
 * @param form : contenido para el campo de parameters
 */
  updateVehiculoEmpresa(form: updateVehiculoEmpresaRequestVO): Observable<ResponseVO<number>> {
    return this._global.put(form, this.urlUpdateVehiculoEmpresa);
  }


}
