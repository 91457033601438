// general imports
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material';

// imports VO
import { ResponseVO } from '../common/vo/responseVO';
import { RequestVO } from '../common/vo/requestVO';
import { CatalogoResponseVO } from '../common/vo/catalogoResponseVO';

// services
import { DataService } from './data.service';
import { LifeTimeSesionService } from './life-time-session.service';

/**
 * Service created by: irvin uriel
 */

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  constructor(private _http: HttpClient,
    private _life: LifeTimeSesionService,
    private _dataService: DataService,
    public _dialog: MatDialog) { }

  /**
   * Creación de un tipo Global
   * @param form : contenido para el campo de parameters
   */
  put(form: any, url: string): Observable<ResponseVO<any>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    // genera requestVO
    let requestVO = new RequestVO<any>();
    requestVO.parameters = form;
    return this._http
      .put<ResponseVO<any>>(url, requestVO, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }

  /**
   * Busqueda por criterios de un tipo Global
   * @param form : contenido para el campo de parameters
   */
  post(parameters: RequestVO<any>, url: string): Observable<ResponseVO<any>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    // crea httpOptions
    let httpOp = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    return this._http
      .post<ResponseVO<any[]>>(url, parameters, httpOp)
      .pipe(catchError(this._dataService.handleError));
  }
  /**
   * Busqueda por criterios de un tipo Global
   * @param form : contenido para el campo de parameters
   */
  postFilles(parameters: RequestVO<any>, url: string): Observable<ResponseVO<any>> {


    /**
    formDataX.forEach((value, key) => {
      console.log("into form");
      console.log(key + " " + value)
    });
    */
    console.log("imagens");
    console.log(parameters.parameters.imagenes);


    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();

    console.log("parameters");
    console.log(parameters);


    var formDataVal = new FormData();

    formDataVal.append("hola", "hola");
    formDataVal.append("hola2", "hola2");

    formDataVal.append('fildase', '10');

    
    console.log("this.file");
    console.log(parameters);
    console.log("----------------------");

    formDataVal.forEach((value, key) => {
      console.log(key + " " + value)
    });

    //console.log("parameters3");

    //console.log("parameters2");
    //console.log(formDataVal);
    //console.log("final");


    // crea httpOptions
    let httpOp = {
      //headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data', 'Authorization': this._dataService.getToken() })
      //headers: new HttpHeaders({ 'Accept': 'multipart/form-data', 'Content-Type': 'multipart/form-data', 'Authorization': this._dataService.getToken() })
      //headers: new HttpHeaders({ 'Accept-Encoding': 'image/png', 'Accept': 'multipart/form-data', 'Content-Type': 'multipart/form-data', 'Authorization': this._dataService.getToken() })
      //headers: new HttpHeaders({ 'Accept': 'multipart/form-data', 'Content-Type': 'x/application/x-www-form-urlencoded', 'Authorization': this._dataService.getToken() })
      //headers: new HttpHeaders({ 'Accept': 'multipart/form-data', 'Content-Type': 'multipart/form-data', 'Authorization': this._dataService.getToken() })
      //headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data', 'Authorization': this._dataService.getToken() })
      //application/x-www-form-urlencodedapplication/x-www-form-urlencoded
      headers: new HttpHeaders({ 'Authorization': this._dataService.getToken() })
      
    }
    

    console.log("Prev send");
    console.log(parameters.parameters);
    console.log("Prev send imgs");
    console.log(parameters.parameters.imagenes);
    


    let formDataSend = new FormData(); 

    let inc_new = 0;
    parameters.parameters.imagenes.forEach((value, key) => {
      console.log("PREV SEND IMG");
      console.log(key + " " + value)
      console.log(key + " " + value.name)


      let images_index = 'imagenes[' + inc_new + ']'

      //formDataSend.append('imagenes[]', value, value.name);
      //formDataSend.append('imagenes', value, value.name);
      //formDataSend.append(images_index, value, value.name);
      formDataSend.append('imagenes', value, value.name);

      inc_new++;
    });

    formDataSend.append('idComponente', '1');
    formDataSend.append('nombreTipoComponente', 'PERSONA');
    formDataSend.append('nombreTipoImagen', 'PERFIL');
    
    //formData.append(‘file’, this.fileToUpload, this.fileToUpload.name); 


    return this._http
      //.post<ResponseVO<any[]>>(url, parameters.parameters, httpOp)
      .post<ResponseVO<any[]>>(url, formDataSend, httpOp)
      .pipe(catchError(this._dataService.handleError));

      

      /*

    return this._http
      .post<ResponseVO<any[]>>(url, parameters, httpOp)
      .pipe(catchError(this._dataService.handleError));



      return this._http.post('http://aguitech.com/yoin_test/file.php', parameters<RequestVO>)
      .pipe(
        //uploadProgress(progress => (this.percentDone = progress)),
        toResponseBody()
      ).subscribe(response => {
        //this.progress = 0;
        //this.signup.reset();
        // do something with the response
      });
      */
  }

  get(url: string): Observable<ResponseVO<any>> {
    // validar si se refresca el token o continua con el token actual
    this._life.resetToken();
    // crea httpOptions
    let httpOp = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this._dataService.getToken() })
    }
    return this._http.get<ResponseVO<any>>(url, httpOp).pipe(catchError(this._dataService.handleError));
  }
}
