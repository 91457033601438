import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'btn-negative',
  templateUrl: './button-negative.component.html',
  styleUrls: ['./button-negative.component.css']
})
export class ButtonNegativeComponent {

  @Output() btnNegativeEmit = new EventEmitter<any>();

  @Input() titulo:string;

  constructor() { }

  click() {
    this.btnNegativeEmit.emit();
  }
}
