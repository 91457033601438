import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Crud } from 'src/app/common/utils/validate-funcions.service';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ModalData } from 'src/app/common/vo/modalData';
import { MatDialogRef } from '@angular/material';
import { REGEX } from 'src/app/common/utils/configUrl';
import { ChatFormComponent } from 'src/app/common/components/chat-form/chat-form.component';


@Component({
  selector: 'app-estandar',
  templateUrl: './estandar.component.html',
  styleUrls: ['./estandar.component.css']
})
export class EstandarComponent implements OnInit {

  public permisosTipo: Crud;
  public form: FormGroup;
  public formGroup: FormGroup;
  @ViewChild('vaadinUpload') uploadvaadin: ElementRef;




  public idiomaList = [
    { value: 'es_MX', valueVisible: 'Español México' },
    { value: 'en_US', valueVisible: 'Ingles USA' }
  ];

  public idioma: string;

  public cardTitleCode = `
  <div class="card">
    <app-title-card [title]="'Titulo'" [icon]="'dashboard'"></app-title-card>
  </div>
    `;
  public btnSearchCode = `
  <btn-search></btn-search>
    `;
  public importModal = `
  import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


  constructor(public dialogRef: MatDialogRef<NombreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    `;
  public btnAdd = `
  <btn-create></btn-create>
  <table class="table table-striped table-no-bordered table-hover compact" cellspacing="0"
    width="100%" style="width:100%">
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Descripción</th>
        <th>Idioma</th>
        <th></th>
      </tr>
    </thead>
  </table>
    `;

    public modalDialog = `
    <div class="main-content">
    <div class="container-fluid form-minsize">
        <div class="for-row">
            <div class="col-md-12">
                <div class="card ">
                    <app-title-card [title]="'Titulo'" [icon]="'dashboard'"></app-title-card>
                    <div class="card-body">
                        <form class="form-horizontal" autocomplete="off">
                            <div class="row">
                                <label class="col-md-4 col-2 col-form-label">Nombre</label>
                                <div class="col-md-8 col-9">
                                    <mat-form-field class="example-full-width" [floatLabel]="'never'">
                                        <input matInput type="text" placeholder="Captura nombre">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-md-4 col-2 col-form-label">Description</label>
                                <div class="col-md-8 col-9">
                                    <mat-form-field class="example-full-width" [floatLabel]="'never'">
                                        <input matInput type="text" placeholder="Captura descripcion">
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer ">
                        <div class="row">
                            <div class="col-md-4">
                              <btn-negative [titulo]="'Cancelar'" (btnNegativeEmit)="cerrar()"></btn-negative>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                              <btn-positive [titulo]="'Actualizar'"></btn-positive>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    `;
  public label = `
  <div class="form-group row">
  <label class="col-2 col-form-label align-middle">Titulo</label>
  <div class="col-10">
    <label class="lbl-border w-100">
      texto no editable
    </label>
  </div>
</div>
    `;
  public btns = `
  <btn-negative [titulo]="'Cancelar'"></btn-negative>
  <btn-positive [titulo]="'Aceptar'"></btn-positive>
    `;

  public cardCodeIcon = `
<i class="material-icons">accessibility_new</i>`;
  public modal = `
import { MatDialog } from '@angular/material';


@Component({
  selector: 'modal-example',
})
export class ModalExample {


  constructor(private dialog: MatDialog) { }

  showModal() {
    this.dialog.open(NombreComponent, {
      data: <<data>>,
      panelClass: 'custom',
      disableClose: true,
    });
  }
}`;
  public clickShowModal = `
  <button mat-raised-button (click)="showModal()">Show Modal</button>
  `;
  public module1 = `
  @NgModule({
    imports:      [
      ...
    ],
    declarations: [
        AppComponent,
        .
        .
        .`;
  public module2 = `
      ],
      bootstrap:    [ AppComponent ],
      entryComponents: [ `;
  public module3 = `
        ]

})`;

  public cardCodeTable1 = `
  <table class="table table-striped table-no-bordered table-hover compact">
    <thead>
        <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Idioma</th>
            <th>
                <btn-create></btn-create>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>PRIVADO</td>
            <td>PRIVADO</td>
            <td>Español México</td>
            <td class="text-right">
                <!-- componente editar -->
                <app-button-edit [row]="row" [permisosFunciones]="permisosTipo"></app-button-edit>
                <!-- componente delete -->
                <app-button-delete [row]="row" [permisosFunciones]="permisosTipo"></app-button-delete>
            </td>
        </tr>
    </tbody>
</table>`;

  public cardCodeTable2 = `
<table class="table table-striped table-no-bordered table-hover compact">
    <thead>
        <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Idioma</th>
            <th>
                <btn-create></btn-create>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>PRIVADO</td>
            <td>PRIVADO</td>
            <td>Español México</td>
            <td class="text-right">
                <button mat-button [matMenuTriggerFor]="menu"><i class="material-icons">more_vert</i></button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <mat-icon>edit</mat-icon>Item 1
                    </button>
                    <button mat-menu-item>
                        <mat-icon>close</mat-icon>Item 2
                    </button>
                    <button mat-menu-item>Item 3</button>
                    <button mat-menu-item>Item 4</button>
                </mat-menu>
            </td>
        </tr>
    </tbody>
</table>`;

  public cardCodeTab = `
<div class="card">
<div class="card-header card-header-tabs card-header-primary">
  <div class="nav-tabs-navigation">
    <div class="nav-tabs-wrapper">
      <span class="nav-tabs-title">Tasks:</span>
      <ul class="nav nav-tabs" data-tabs="tabs">
        <li class="nav-item active">
          <a href="#profile" class="nav-link" data-toggle="tab">
            <i class="material-icons">bug_report</i> First
            <div class="ripple-container"></div>
          </a>
        </li>
        <li class="nav-item">
          <a href="#messages" class="nav-link" data-toggle="tab">
            <i class="material-icons">code</i> Second
            <div class="ripple-container"></div>
          </a>
        </li>
        <li class="nav-item">
          <a href="#settings" class="nav-link" data-toggle="tab">
            <i class="material-icons">cloud</i> Third
            <div class="ripple-container"></div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="card-body">
  <div class="tab-content">
    <div class="tab-pane active" id="profile">
      Aquí va tu contenido (Tab 1)
    </div>
    <div class="tab-pane" id="messages">
      Aquí va tu contenido (Tab 2)
    </div>
    <div class="tab-pane" id="settings">
      Aquí va tu contenido (Tab 3)
    </div>
  </div>
</div>
</div>`;

  public cardCodeStepper = `
<div class="wizard-container">
<div class="card card-wizard" data-color="blue" id="wizardProfile">
  <div class="card-header text-center">
  </div>
  <div class="wizard-navigation">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link active" href="#titulo1" data-toggle="tab" role="tab">
          Titulo 1
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#titulo2" data-toggle="tab" role="tab">
          Titulo 2
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#titulo3" data-toggle="tab" role="tab">
          Titulo 3
        </a>
      </li>
    </ul>
  </div>
  <div class="tab-content">
    <div class="tab-pane active" id="titulo1">
      Contenido de titulo 1
    </div>
    <div class="tab-pane" id="titulo2">
      Contenido de titulo 2
    </div>
    <div class="tab-pane" id="titulo3">
      Contenido de titulo 3
    </div>
  </div>
</div>
</div>`;

  public cardCodeHttp = `
// general imports
import { Injectable } from '@angular/core';
import { endpoints } from './../common/utils/configUrl';
import { Observable} from 'rxjs';

// imports VO
import { ResponseVO } from '../common/vo/responseVO';
import { RequestVO } from '../common/vo/requestVO';
import { CatalogoVO } from '../common/vo/catalogoVO';
import { CatalogoResponseVO } from '../common/vo/catalogoResponseVO';

// services
import { GlobalService } from './global.service';

@Injectable({
 providedIn: 'root'
})

export class TelefonosService {
 private urlCreate: string = endpoints.TiposTelefonosCreate;
 private urlFindByAllFields: string = endpoints.TiposTelefonosFindByAllFields;
 private urlDelete: string = endpoints.TiposTelefonosDelete;
 private urlUpdate: string = endpoints.TiposTelefonosUpdate;
 //private _service:GlobalService;
 constructor(private _service:GlobalService) { }
 /**
  * Creación de un tipo telefono
  * @param form : contenido para el campo de parameters
  */
 tiposTelefonosCreate(form: RequestVO<CatalogoVO>): Observable<ResponseVO<CatalogoVO>> {
   return this._service.post(form, this.urlCreate);
 }

 /**
  * Actualización tipo telefono
  * @param form : contenido para el campo de parameters
  */
 tiposTelefonosUpdate(form: CatalogoVO): Observable<ResponseVO<CatalogoResponseVO>> {
   return this._service.put(form, this.urlUpdate);
 }
  /**
  * Eliminacion de un tipo telefono
  * @param id : id de un dato de tipo telefono
  */
 tiposTelefonosDelete(parameters: RequestVO<CatalogoVO>): Observable<ResponseVO<CatalogoVO>> {
   return this._service.post(parameters, this.urlDelete);
 }

 /**
  * Busqueda por criterios de un tipo telefono
  * @param form : contenido para el campo de parameters
  */
 tiposTelefonosFindByAllFields(parameters: RequestVO<CatalogoVO>): Observable<ResponseVO<CatalogoResponseVO[]>> {
   return this._service.post(parameters, this.urlFindByAllFields);
 }
}`;

  public cardCodeSelect = `
archivo.html
___________________________________________________________
<div class="form-group row">
    <label class="col-2 col-form-label label-middle">Select</label>
    <div class="col-10">
        <mat-form-field class="example-full-width" [floatLabel]="'never'">
            <mat-select placeholder="IDIOMAS">
                <mat-option *ngFor="let idiomas of idiomaList" [value]="idiomas.value">
                    {{idiomas.valueVisible}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>


archivo.ts
___________________________________________________________

public idiomaList = [
  {value: 'es_MX', valueVisible: 'Español México'},
  {value: 'en_US', valueVisible: 'Ingles USA'}
];`;

  public cardCodeInputTexarea = `
<div class="form-group row">
    <label class="col-2 col-form-label label-middle">input</label>
    <div class="col-10">
        <mat-form-field class="w-100" [floatLabel]="'never'">
            <input matInput placeholder="Input">
        </mat-form-field>
    </div>
</div>

<div class="form-group row">
    <label class="col-2 col-form-label label-middle">textarea</label>
    <div class="col-10">
        <mat-form-field class="w-100" [floatLabel]="'never'">
            <textarea matInput placeholder="Textarea"></textarea>
        </mat-form-field>
    </div>
</div>`;

  public cardCodeDatepicker = `
<div class="form-group row">
    <label class="col-2 col-form-label label-middle">Select</label>
    <div class="col-10">
        <mat-form-field>
            <input matInput [matDatepicker]="picker" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker" class="w-100"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
</div>`;

  public cardCodeCheckbox = `
<mat-checkbox>Check me!</mat-checkbox>`;

  public cardCodeRadioButton = `
<mat-radio-group aria-label="Select an option">
  <mat-radio-button value="1">Option 1</mat-radio-button>
  <mat-radio-button value="2">Option 2</mat-radio-button>
</mat-radio-group>`;

  public cardCodeSlider = `
<mat-slider></mat-slider>`;

  public cardCodeSliderToggle = `
<mat-slide-toggle>Slide me!</mat-slide-toggle>`;

  public cardCodeGrid = `
<div class="container">
    <div class="row">
        <div class="col">
            1 of 2
        </div>
        <div class="col">
            2 of 2
        </div>
    </div>
    <div class="row">
        <div class="col">
            1 of 3
        </div>
        <div class="col">
            2 of 3
        </div>
        <div class="col">
            3 of 3
        </div>
    </div>


    <div class="row">
        <div class="col-sm-8">col-sm-8</div>
        <div class="col-sm-4">col-sm-4</div>
    </div>
    <div class="row">
        <div class="col-sm">col-sm</div>
        <div class="col-sm">col-sm</div>
        <div class="col-sm">col-sm</div>
    </div>


    <div class="row">
        <div class="col-6 col-md-4">.col-6 .col-md-4</div>
        <div class="col-6 col-md-4">.col-6 .col-md-4</div>
        <div class="col-6 col-md-4">.col-6 .col-md-4</div>
    </div>


    <div class="row" style="height: 80px">
        <div class="col align-self-start">
            One of three columns
        </div>
        <div class="col align-self-center">
            One of three columns
        </div>
        <div class="col align-self-end">
            One of three columns
        </div>
    </div>
</div>`;

  public cardCodeTraductorEs = `
"login": {
  "title": "Iniciar sesión",
  "user": "Teléfono o correo electrónico",
  "button": "¡Bienvenido!",
  "userRequired": "Usuario requerido",
  "password": "Contraseña",
  "passwordRequired": "Contraseña requerida",
  "send": "Enviar"
}`;

  public cardCodeTraductorEn = `
"login": {
  "title": "Login",
  "user" : "Phone number or E-Mail",
  "button" : "Welcome!",
  "userRequired" : "User required",
  "password" : "Password",
  "passwordRequired" : "Password required",
  "send" : "Login"
}`;

  public cardCodeTraductor = `
"{{ 'common.input.pattern.invalid' | translate }}`;

  public cardCodeValidator = `
class Validators {
  static min(min: number): ValidatorFn
  static max(max: number): ValidatorFn
  static required(control: AbstractControl): ValidationErrors | null
  static requiredTrue(control: AbstractControl): ValidationErrors | null
  static email(control: AbstractControl): ValidationErrors | null
  static minLength(minLength: number): ValidatorFn
  static maxLength(maxLength: number): ValidatorFn
  static pattern(pattern: string | RegExp): ValidatorFn
  static nullValidator(control: AbstractControl): ValidationErrors | null
  static compose(validators: ValidatorFn[]): ValidatorFn | null
  static composeAsync(validators: AsyncValidatorFn[]): AsyncValidatorFn | null
}`;

  public cardCodeValidacion = `
<form [formGroup]="formGroup">
    <mat-form-field class="example-full-width" [floatLabel]="'never'">
        <input matInput type="text" formControlName="idNombre"
            placeholder="{{ 'tipoTelefono.placeholder.key' | translate }}">
        <mat-error *ngIf="controlsForm.idNombre.hasError('required')">
            {{ 'tipoTelefono.input.key.required' | translate }}</mat-error>
        <mat-error *ngIf="controlsForm.idNombre.hasError('pattern')">
            {{ 'common.input.pattern.invalid' | translate }}</mat-error>
        <mat-error *ngIf="controlsForm.idNombre.hasError('minlength')">{{ 'common.input.minsize' | translate }}
        </mat-error>
        <mat-error *ngIf="controlsForm.idNombre.hasError('maxlength')">{{ 'common.input.maxsize' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width" [floatLabel]="'never'">
        <input matInput type="text" formControlName="correo" placeholder="EMAIL">
        <mat-error *ngIf="controlsForm.correo.hasError('required')">
            El correo es requerido</mat-error>
        <mat-error *ngIf="controlsForm.correo.hasError('email')">
            El correo es invalido</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width" [floatLabel]="'never'">
        <input matInput type="text" formControlName="edad" placeholder="Ej. 30">
        <mat-error *ngIf="controlsForm.edad.hasError('required')">
            El correo es requerido</mat-error>
        <mat-error *ngIf="controlsForm.edad.hasError('pattern')">
            Solo capturar números</mat-error>
    </mat-form-field>
</form>
`;

  public cardCodeValidacionts = `
constructor(private _formBuilder: FormBuilder) {
 }

ngOnInit() {
  this.buildForm();
}

private buildForm() {
  this.formGroup = this._formBuilder.group({
    idNombre: ['',[Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(REGEX.letrasNumeros)]],
    correo: ['',[Validators.required, Validators.email]],
    edad: ['',[Validators.required, Validators.pattern("[0-9]+$")]]
  });
}

get controlsForm() {
  return this.formGroup.controls;
}`;

public cardCodeLocalStorage = `
let variableLocal = this._data.retriveStorage("saludo");
    if (!variableLocal) {
      this._data.addStore("saludo", "La variable saludo esta en localStorage");
    }`;

  constructor(private _formBuilder: FormBuilder, private _data: DataService) {
    this.permisosTipo = { create: true, read: true, update: true, delete: true };
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      one: ['', Validators.required]
    });

    let variableLocal = this._data.retriveStorage('saludo');
    if (!variableLocal) {
      this._data.addStore('saludo', "La variable saludo esta en localStorage");
    }

    this.buildForm();
  }

  private buildForm() {
    this.formGroup = this._formBuilder.group({
      idNombre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(REGEX.letrasNumeros)]],
      correo: ['', [Validators.required, Validators.email]],
      edad: ['', [Validators.required, Validators.pattern("[0-9]+$")]]
    });
  }

  get controlsForm() {
    return this.formGroup.controls;
  }

  showModal() {
    let modal = new ModalData();
    modal.component = ModalExample;
    modal.disableClose = false;
    this._data.setModalData(modal);
  }



}


@Component({
  selector: 'modal-example',
  template: `
  <div class="main-content">
    <div class="container-fluid form-minsize">
        <div class="for-row">
            <div class="col-md-12">
                <div class="card ">
                    <app-title-card [title]="'Titulo'" [icon]="'dashboard'"></app-title-card>
                    <div class="card-body">
                        <form class="form-horizontal" autocomplete="off">
                            <div class="row">
                                <label class="col-md-4 col-2 col-form-label">Nombre</label>
                                <div class="col-md-8 col-9">
                                    <mat-form-field class="example-full-width" [floatLabel]="'never'">
                                        <input matInput type="text" placeholder="Captura nombre">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-md-4 col-2 col-form-label">Description</label>
                                <div class="col-md-8 col-9">
                                    <mat-form-field class="example-full-width" [floatLabel]="'never'">
                                        <input matInput type="text" placeholder="Captura descripcion">
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer ">
                        <div class="row">
                            <div class="col-md-4">
                              <btn-negative [titulo]="'Cancelar'" (btnNegativeEmit)="cerrar()"></btn-negative>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                              <btn-positive [titulo]="'Actualizar'"></btn-positive>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`,
})
export class ModalExample {


  constructor(public _dialogRef: MatDialogRef<any>) { }

  cerrar() {
    this._dialogRef.close();
  }
}
