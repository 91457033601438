import { Component, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { TelefonosService } from 'src/app/services/telefonos.service';
import { RequestVO } from '../../vo/requestVO';
import { CatalogoVO } from '../../vo/catalogoVO';
import { ErrorVO } from '../../vo/errorVO';
import { DataService } from 'src/app/services/data.service';
import { LifeTimeSesionService } from 'src/app/services/life-time-session.service';
import { IdiomaVO } from '../../vo/idiomaVO';

@Component({
  selector: 'chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.css']
})
export class ChatFormComponent {

  @Output() sendEmit = new EventEmitter<any>();

  @Input() maxMessageView: number = 10;

  @Input() messageCount: number;

  @ViewChild('chatContainer')
  chatContainer: ElementRef;

  message: string;

  disabledSend: boolean = true;

  user: string = 'es_MX';

  page: number = 1;

  length: number;

  dataSet: Array<any> = new Array();

  constructor(private _telefonosService: TelefonosService,
    private _dataService: DataService,
    private _life: LifeTimeSesionService) {
    this.viewInit(true);
    console.log(this.dataSet);
  }

  ngAfterViewInit() {
  }

  viewMore() {
    this.page++;
    this.viewInit(false);
  }

  viewInit(viewAll: boolean) {
    /*let params = new RequestVO<CatalogoVO>();
    params.parameters = new CatalogoVO();
    params.page = this.page;
    params.size = this.maxMessageView;
    params.orderBy = "id";
    params.orderType = "desc";
    this._telefonosService.tiposTelefonosFindByAllFields(params).subscribe(
      (response) => {
        // se agrega total de paginas a la tabla
        this.messageCount = Math.ceil(response.totalRows);
        // Si la transaccion fue exitosa, se carga la informacion a la tabla
        // de lo contrario se muestran los errores recibidos.
        if (response.success) {
          if (viewAll) {
            response.data.reverse();
          }

          response.data.forEach((element) => {
            if (viewAll) {
              this.dataSet.push({ message: element.descripcion, date: '24/07/2019 11:40', user: element.idioma.idNombre });
            } else {
              this.dataSet.unshift({ message: element.descripcion, date: '24/07/2019 11:40', user: element.idioma.idNombre });
            }

          });
          console.log(this.dataSet);
        } else {
          this.dataSet = [];
        }
        setTimeout(() => {
          this.chatContainer.nativeElement.scrollTop = 100000;
        }, 0);
      },
      (error: ErrorVO[]) => {
        //Valida token   -604 = El token no es válido
        if (error[0].code === '-604') {
          // logOut
          this._life.logOut();
        }
        // Se muestran otros errores, no tratados o relacionados por el backend.
        this._dataService.addErrors(error);
        this._dataService.stopLoading();


      });*/

  }

  evaluaUsuario(usarioMensaje: string) {
    return usarioMensaje === this.user;
  }

  onKey(value: string) {
    this.disabledSend = value.length == 0 ? true : false;
  }

  send() {
    this._dataService.startLoading();
    let params = new RequestVO<CatalogoVO>();
    let datos = new CatalogoVO();
    let idioma = new IdiomaVO();
    datos.idNombre = this.message;
    datos.descripcion = this.message;
    idioma.idNombre = 'es_MX';
    datos.idioma = idioma;
    params.parameters = datos;
    this._telefonosService.tiposTelefonosCreate(params).subscribe(
      (response) => {
        // Valida la respuesta recibida por la solicitud de creacion.
        if (response.success) {
          // Si el proceso fue exitoso, envia un mensaje al servicio encargado
          // de la actualizacion de la data del listado en el componente padre.
          this._dataService.setFetchData(true);
          this.dataSet.push({ message: this.message, date: '24/07/2019 11:40', user: this.user });
          this.message = '';
          this.messageCount++;
          this.maxMessageView++;
          setTimeout(() => {
            this.chatContainer.nativeElement.scrollTop = 100000;
          }, 0);
        } else {
          this._dataService.addErrors(response.errors);
        }
      },
      (error: ErrorVO[]) => {
        this._dataService.addErrors(error);
      });
  }


}
