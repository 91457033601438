import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'btn-positive',
  templateUrl: './button-positive.component.html',
  styleUrls: ['./button-positive.component.css']
})
export class ButtonPositiveComponent {

  @Output() btnPositiveEmit = new EventEmitter<any>();

  @Input() titulo:string;

  constructor() { }

  click() {
    this.btnPositiveEmit.emit();
  }
}
